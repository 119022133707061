export const labelItems = {
  // ========== learning curve & study buddy ========
  serviceTypes: "Service Type",
  isSubsidy: "Do you need subsidy?",
  isDeclare: "I declare that the above information is true.",
  isCollecting: "I consent to CampusImpact using the above personal data ...",
  isUsing:
    "I consent to CampusImpact collecting and using the personal data ...",
  isPublish: "I consent to CampusImpact to publish photographs or videos",
  fullName: "Full Name",
  unitNumber: "Unit Number",
  postalCode: "Postal Code",
  address: "Street",
  gender: "Gender",
  dob: "Date of Birth",
  contact: "Home Phone",
  race: "Race",
  otherRace: "Please specify race",
  nric: "NRIC/Birth Cert No.",
  educationLevel: "Education Level",
  schoolName: "Name of School ( Current Year)",
  mainConcern: "Concerns",
  noFamilyMembers: "No. family members staying together in the house",
  accommodationType: "Accommodation Type",
  houseHoldIncome: "Household Income",
  incomeAbove: "Income Above $4,000",

  // ========== learning curve ========
  subjects: "Subject",
  chineseName: "Name in Chinese Characters",
  phone: "Phone Number",
  email: "Email",
  nationality: "Nationality",
  stream: "Stream",
  recentExamResults: "Recent Exam Results",
  parentSalutation: "Salutation",
  parentFullName: "Full Name",
  parentContact: "Phone Number",
  parentNRIC: "NRIC/Birth Cert No.",
  parentEmail: "Parent Email",
  relationshipToStudent: "Relationship to Student",
  parentNationality: "Nationality",
  spouseName: "Spouse Name",
  cpfContribution: "Upload CPF Statement",
  payslip: "Upload Payslip",

  // ========== study buddy ========
  // -------- 1. step student information ---------
  session: "Session",
  holidayTiming: "Holiday Timing",
  time: "Time ",
  sbFullName: "Full Name",
  sbNric: "NRIC/Birth Cert No.",
  sbGender: "Gender",
  sbSchoolName: "Name of School ( Current Year)",
  sbDob: "Date of Birth",
  sbEducationLevel: "Education Level",
  sbRace: "Race",
  sbOtherRace: "Please specify race",
  citizenship: "Citizenship",
  otherCitizenship: "Please specify citizenship",
  sbPostalCode: "Postal Code",
  sbUnitNumber: "Unit Number",
  sbAddress: "Street",
  sbContact: "Home Phone",
  punctuality: "Punctuality",

  // --------- 2. step family profile ---------------------
  dayLate: "Which of the following day/s your child/ward will be late ...",
  reasonComing: "Reason for coming in late to CampusImpact ",
  dayNotAttending:
    "Which of the following day/s your child will not be attending...",
  reasonNotAttending:
    "Reason for not attending Study Buddy Programme (eg. CCA)",
  fatherNotApplicable: "Father Not Applicable",
  fatherName: "Father's Name",
  fatherNric: "NRIC No.",
  fatherContactNo: "Contact No.",
  fatherEmail: "Father's Email",
  fatherOccupation: "Father Occupation",
  motherNotApplicable: "Mother Not Applicable",
  motherName: "Mother's Name",
  motherNric: "NRIC No.",
  motherContactNo: "Contact No.",
  motherEmail: "Mother's Email",
  motherOccupation: "Mother Occupation",
  maritalStatuses:
    "Please select one of the marital statuses of the child's parents",
  mainCaregiver: "Main Caregiver",
  guardianApplicable: "Guardian Applicable",
  guardianName: "Guardian's Name",
  guardianContactNo: "Contact No.",
  guardianNric: "NRIC No.",
  guardianEmail: "Email",

  // --------- 3. Household and Other Form ---------------------
  whoIsStaying: "Please state who is staying in the same household",
  allergies: "Allergies & Medications",
  sourceOfReferral: "Source of Referral",
  provideSourceOfReferral: "Kindly provide the source of referral",
  payslipSB: "Upload Payslip",
  sbNoFamilyMembers: "No. family members staying together in the house",
  sbHouseholdIncome: "Family Gross Salary ",
  sbIncomeAbove: "Income Above $4,000",
  sbAccommodationType: "Accommodation Type",
};
