import * as Yup from "yup";
import {
  lsFields,
  LSFieldValidation,
  LSFieldValidationNew,
  subsidyFields,
  SubsidyValidation,
  SubsidyValidationNew,
  validateContact,
  validateDob,
  validateNRIC,
} from "./validationHelper";
import { fnUniqueNRIC } from "./fnUniqueNRIC";

Yup.addMethod(Yup.string, "postalCode", function (err) {
  return this.test("postalCode", err, (str) =>
    str?.length === 6 ? !isNaN(str) : false
  );
});

export const LSSchema = {
  enquiryId: Yup.string(),
  academicId: Yup.string(),
  serviceTypes: Yup.array(),
  isDraft: Yup.bool(),
  step: Yup.number(),

  isSubsidy: Yup.bool(),
  isDeclare: Yup.bool().when(lsFields, {
    is: LSFieldValidationNew(2),
    then: Yup.bool().oneOf([true], "Field must be checked"),
  }),
  isCollecting: Yup.bool().when(lsFields, {
    is: LSFieldValidationNew(2),
    then: Yup.bool().oneOf([true], "Field must be checked"),
  }),
  isUsing: Yup.bool().when(lsFields, {
    is: LSFieldValidationNew(2),
    then: Yup.bool().oneOf([true], "Field must be checked"),
  }),
  isPublish: Yup.bool().when(lsFields, {
    is: LSFieldValidationNew(2),
    then: Yup.bool().oneOf([true], "Field must be checked"),
  }),

  fullName: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string().required("This field is required"),
  }),
  chineseName: Yup.string().nullable(),
  unitNumber: Yup.string().matches(/^[\d-]{1,6}$/, "Unit number is invalid"),
  postalCode: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string().postalCode().required("This field is required"),
  }),
  address: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string().required("This field is required"),
  }),
  gender: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string().required("This field is required"),
  }),
  dob: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string()
      .required("This field is required")
      .test("dob", "Sorry, your date of birth can't be in the future", (val) =>
        validateDob("dob", val)
      ),
  }),
  contact: Yup.string()
    .test("contact", "Please provide a valid contact number", (value) =>
      !value.length ? true : /^na$/i.test(value) ? true : validateContact(value)
    )
    .when(lsFields, {
      is: LSFieldValidationNew(0),
      then: Yup.string()
        .required("This field is required")
        .test("contact", "Please provide a valid contact number", (value) =>
          /^na$/i.test(value) ? true : validateContact(value)
        ),
    }),
  sbContact: Yup.string()
    .test("sbContact", "Please provide a valid contact number", (value) =>
      !value.length ? true : /^na$/i.test(value) ? true : validateContact(value)
    )
    .when(lsFields, {
      is: LSFieldValidationNew(0),
      then: Yup.string()
        .required("This field is required")
        .test("sbContact", "Please provide a valid contact number", (value) =>
          /^na$/i.test(value) ? true : validateContact(value)
        ),
    }),
  race: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string().required("This field is required"),
  }),
  otherRace: Yup.string().when(["race"], {
    is: (val) => /others/i.test(val),
    then: Yup.string().required("This field is required"),
  }),
  nric: Yup.string()
    .test("nric", null, (str, ctx) => validateNRIC(str, ctx, "nric"))
    .test("nric", "NRIC must be unique", fnUniqueNRIC)
    .when(lsFields, {
      is: LSFieldValidationNew(0),
      then: Yup.string().required("Please provide ID number"),
    }),
  educationLevel: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string().nullable().required("This field is required"),
  }),
  schoolName: Yup.string().when(lsFields, {
    is: LSFieldValidationNew(0),
    then: Yup.string().required("This field is required"),
  }),
  mainConcern: Yup.array(),

  // ====== Subsidy ======
  noFamilyMembers: Yup.string().when(subsidyFields, {
    is: SubsidyValidationNew(2),
    then: Yup.string().required("This field is required"),
  }),
  accommodationType: Yup.string().when(subsidyFields, {
    is: SubsidyValidationNew(2),
    then: Yup.string().required("This field is required"),
  }),
  houseHoldIncome: Yup.string().when(subsidyFields, {
    is: SubsidyValidationNew(2),
    then: Yup.string().required("This field is required"),
  }),
  incomeAbove: Yup.string().when(["isSubsidy", "houseHoldIncome"], {
    is: (isSubsidy, income) => isSubsidy && /^above+/i.test(income),
    then: Yup.string().required("This field is required"),
  }),
};
