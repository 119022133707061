import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { labelItems } from "./labelNameOfPayload";

const styleBox = {
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  borderRadius: "6px",
  outline: "none",
};

const ModalValidation = ({ show, onClose, errors }) => {
  const [listErrors, setListErrors] = useState([]);

  useEffect(() => {
    let tmp = [];
    for (const key in errors) {
      if (Object.hasOwnProperty.call(errors, key)) {
        const item = errors[key];
        const label = labelItems[key];
        tmp = [...tmp, { key: label ?? key, value: item?.message }];
      }
    }
    setListErrors(tmp);
  }, [errors]);
  return (
    <Modal open={show}>
      <Box sx={styleBox}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          sx={{ padding: "20px", boxShadow: "inset 0px -1px 0px #F2F2F2" }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          >
            Submission Error
          </Typography>
          <Box>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </Stack>
        <Box sx={{ padding: "20px", maxHeight: "43vh", overflow: "auto" }}>
          <List>
            {listErrors.map((item) => (
              <ListItem key={item.key}>
                <ListItemText
                  primary={
                    <>
                      <span style={{ fontWeight: 500 }}>{item.key}</span> :{" "}
                      {item.value}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalValidation;
