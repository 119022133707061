import React, { useEffect, useState } from "react";
import { Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import SectionTitle from "../../../../Components/Section/SectionTitle";
import ReactSelect from "../../../../Components/Select/ReactSelect";
import { getPicklistValues } from "../../../../datasources/enquiryRepo";
import { childExperiencedProblemsOptions } from "../options";
import FieldError from "../../../../Components/Forms/FieldError";
import { Controller, useFormContext } from "react-hook-form";

const ChildExperiencedProblems = () => {
  const { control, setValue } = useFormContext();

  return (
    <Form.Group className="form-group">
      <Form.Label>
        Has your child experienced any of the following problems at school?
      </Form.Label>
      <Typography>
        Indicate “<b>Y</b>” if there isn't or or “<b>N</b>” if there isn't
      </Typography>
      <Row style={{ marginTop: "20px" }}>
        {childExperiencedProblemsOptions.map((options, i) => (
          <Col key={i} md={4}>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              spacing={4}
              sx={{ marginLeft: "15px" }}
            >
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Y
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                N
              </Typography>
            </Stack>
            {options.map((option) => (
              <Stack key={option} direction={"row"} alignItems={"center"}>
                <Controller
                  control={control}
                  name="childExperiencedProblems"
                  render={({ field: { value } }) => (
                    <RadioGroup
                      row
                      onChange={(e) => {
                        if (e.target.value === "true") {
                          const tmpRes = [...value, option];
                          value.push(option);
                          setValue("childExperiencedProblems", tmpRes);
                        } else if (e.target.value === "false") {
                          setValue(
                            "childExperiencedProblems",
                            value.filter((item) => item !== option)
                          );
                        }
                      }}
                    >
                      <Radio value={true} checked={value.includes(option)} />
                      <Radio value={false} />
                    </RadioGroup>
                  )}
                />
                <Typography variant="body1">{option}</Typography>
              </Stack>
            ))}
          </Col>
        ))}
      </Row>
    </Form.Group>
  );
};

const StepEducationHistory = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const [educationLevels, setEducationLevels] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        let response = await getPicklistValues();
        setEducationLevels(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <Section>
        <SectionTitle title="Educational History" />
        <SectionBody>
          <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: "600" }}>
            What school does your child attend?
          </Typography>
          <Form.Group className="form-group">
            <Form.Label>Name of school</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name of school"
              {...register("schoolName")}
            />
            <FieldError message={errors?.schoolName?.message} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Form teacher's name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Form teacher's name"
              {...register("formTeacherName")}
            />
            <FieldError message={errors?.formTeacherName?.message} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Current class</Form.Label>
            <Form.Control
              type="text"
              placeholder="Current class"
              {...register("currentClass")}
            />
            <FieldError message={errors?.currentClass?.message} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              What does your child's teacher say about him/her?
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childsTeacherSayAbout")}
            />
            <FieldError message={errors?.childsTeacherSayAbout?.message} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Has your child ever repeated a class? If so which one(s)?
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverRepeatedAClass")}
            />
            <FieldError message={errors?.childEverRepeatedAClass?.message} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Has your child ever received special education services?
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverReceivedEducationServices")}
            />
            <FieldError
              message={errors?.childEverReceivedEducationServices?.message}
            />
          </Form.Group>
          <ChildExperiencedProblems />
        </SectionBody>
      </Section>
      <Section>
        <SectionTitle title="Other History" />
        <SectionBody>
          <Form.Group className="form-group">
            <Form.Label>
              Has your child ever experienced any type of abuse (physical,
              sexual, or verbal? If so, please describe:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverExperiencedAbuse")}
            />
            <FieldError message={errors?.childEverExperiencedAbuse?.message} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Has your child ever made statements of wanting to hurt him/herself
              or seriously hurt someone else?
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverMadeStatementsOfWantingToHurt")}
            />
            <FieldError
              message={errors?.childEverMadeStatementsOfWantingToHurt?.message}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Has he/she ever purposely hurt himself or another? If yes to
              either question please describe the situation:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverPurposelyHurtHimself")}
            />
            <FieldError
              message={errors?.childEverPurposelyHurtHimself?.message}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Has your child ever experienced any serious emotional losses (such
              as a death of or physical separation from a parent or other
              caretaker)? If yes, please explain:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverExperiencedAnySeriousEmitionalLosses")}
            />
            <FieldError
              message={
                errors?.childEverExperiencedAnySeriousEmitionalLosses?.message
              }
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Finally, what are some of the things that are currently stressful
              to your child and his/her family?
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("thingsThatAreCurrentlyStressful")}
            />
            <FieldError
              message={errors?.thingsThatAreCurrentlyStressful?.message}
            />
          </Form.Group>
        </SectionBody>
      </Section>
    </>
  );
};

export default StepEducationHistory;
