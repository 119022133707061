import { methodService, apiService } from "./apiServices";

export const URL = {
  BASE_ENQUIRIES: "/enquiries",
  BASE_INTAKE: "/intake",
};

export const getPicklistValues = () => {
  return apiService(
    URL.BASE_ENQUIRIES + "/formPicklistValues",
    methodService.GET,
    null,
    null
  );
};

export const getEnquiryData = (data) => {
  return apiService(
    URL.BASE_ENQUIRIES + `/${data.id}`,
    methodService.GET,
    data,
    null
  );
};

export const getEnquiryDataImpactCare = (data) => {
  return apiService(
    URL.BASE_ENQUIRIES + `/impact-care/${data.id}`,
    methodService.GET,
    null,
    null
  );
};

export const submitEnquiryForm = (data) => {
  const fd = new FormData();
  for (const item in data) {
    if (data[item] !== undefined && data[item] !== null) {
      fd.append(
        item,
        typeof data[item] === "object" &&
          !["informedConsentFile"].includes(item)
          ? JSON.stringify(data[item])
          : data[item]
      );
    }
  }
  return apiService(URL.BASE_ENQUIRIES, methodService.POST, fd, null, true);
};

export const submitImpactCareEnquiryForm = (data) => {
  const fd = new FormData();
  for (const item in data) {
    if (data[item] !== undefined && data[item] !== null) {
      fd.append(
        item,
        typeof data[item] === "object" &&
          !["supportingDocFile", "informedConsentFile"].includes(item)
          ? JSON.stringify(data[item])
          : data[item]
      );
    }
  }

  return apiService(
    URL.BASE_ENQUIRIES + "/impact-care",
    methodService.POST,
    fd,
    null
  );
};

export const submitVerificationCode = (data) => {
  return apiService(
    URL.BASE_ENQUIRIES + `/${data.id}/verify`,
    methodService.POST,
    { verificationCode: data.verificationCode },
    null
  );
};

export const submitVerificationCodeImpactcare = (data) => {
  return apiService(
    URL.BASE_ENQUIRIES + `/impact-care/${data.id}/verify`,
    methodService.POST,
    data,
    null
  );
};

export const submitVerificationCodeImpactful = (data) => {
  return apiService(
    URL.BASE_INTAKE + `/${data.id}/verify/impactful`,
    methodService.POST,
    data,
    null
  );
};

export const submitEnquiryCompleteForm = (data) => {
  return apiService(
    URL.BASE_ENQUIRIES + "/complete-form",
    methodService.PATCH,
    data,
    null,
    true
  );
};

export const submitEnquiryCompleteFormImpactCare = (data) => {
  return apiService(
    URL.BASE_ENQUIRIES + "/impact-care/complete-form",
    methodService.PATCH,
    data,
    null
  );
};

export const submitIntakeImpactful = (data) => {
  return apiService(
    URL.BASE_INTAKE + `/impactful`,
    methodService.PATCH,
    data,
    null
  );
};

export const getAllEnquiryNationality = () => {
  return apiService(URL.BASE_ENQUIRIES + "/nationality", methodService.GET);
};
export const getAllEnquiryCitizenship = () => {
  return apiService(URL.BASE_ENQUIRIES + "/citizenship", methodService.GET);
};
