import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CompleteForm } from "./Modules/CompleteForm";
import EnquiryCompleteForm from "./Modules/EnquiryCompleteForm";
import { EnquiryForm } from "./Modules/EnquiryForm";
import { IntakeForm } from "./Modules/EnquiryForm/IntakeForm";
import { Error404 } from "./Modules/Error/404";
import { ThankYou } from "./Modules/ThankYouPage";
import { VerficationForm } from "./Modules/VerificationForm";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Roboto", "sans-serif"],
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#9a9c95",
          ":checked": {
            color: "#FD9344",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#FD9344",
      light: "#ff9d54",
      dark: "#eb8336",
    },
    error: {
      main: "#DA6753",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={EnquiryForm} />
            <Route path={`/enquiry-form`} component={EnquiryForm} />
            <Route path={`/test`} component={() => <span>Test</span>} />
            <Route path={`/verification-form`} component={VerficationForm} />
            <Route path={`/complete-form`} component={CompleteForm} />
            <Route
              path={`/enquiry-complete-form`}
              component={EnquiryCompleteForm}
            />
            <Route path={`/thank-you`} component={ThankYou} />
            <Route path={`/intake-form`} component={IntakeForm} />
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
