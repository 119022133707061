import * as Yup from "yup";
import moment from "moment";
import nric from "nric";
export const contactPattern = /^[689]\d{7}$/i;

const LC = "Learning Curve";
const SB = "Study Buddy";
const stepValidate = 2;

export const validateNRIC = (str, _, name) => {
  if (str.length === 0) {
    return true;
  }
  if (str.length !== 9) {
    return new Yup.ValidationError("Invalid number", null, name);
  } else {
    str = str.toUpperCase();
    const condition = nric.validate(str);
    if (condition) {
      return true;
    } else {
      return new Yup.ValidationError("Invalid number", null, name);
    }
  }
};

export const uniqueNric = (value, ...items) => {
  const counts = {};
  for (const item of items) {
    counts[item] = counts[item] ? counts[item] + 1 : 1;
  }
  return value ? counts[value] <= 1 : true;
};

export const validateFile = (value) => {
  if (value) {
    return value.size <= 10000000;
  }
  return true;
};

export const validateFileExtension = (value, ...extensions) => {
  if (value) {
    const arrName = value.name?.split(".");
    const extension = arrName[arrName?.length - 1];
    return extensions.includes(extension);
  }
  return true;
};

export const validateDob = (name, value) => {
  if (value === "" || value === null) {
    return new Yup.ValidationError("This field is required", null, name);
  } else if (moment(value, "DD-MM-YYYY") > moment()) {
    return new Yup.ValidationError(
      "Your birthday can't be in the future",
      null,
      name
    );
  } else if (
    moment(value, "DD-MM-YYYY") < moment("1970-01-01") ||
    !moment(value, "DD-MM-YYYY").isValid()
  ) {
    return new Yup.ValidationError("Birthday is invalid", null, name);
  } else {
    return true;
  }
};
export const validateContact = (value) => {
  if (value.length > 0) {
    return contactPattern.test(value);
  }
  return true;
};
export const validateRecentExamResults = (value) => {
  let status = value.map((item) => {
    return (
      item.hasOwnProperty("subject") &&
      !!item["subject"] &&
      item.hasOwnProperty("target") &&
      !!item["target"] &&
      item.hasOwnProperty("score") &&
      !!item["score"]
    );
  });
  return !status.includes(false);
};

export const fatherFields = ["fatherNotApplicable"];
export const motherFields = ["motherNotApplicable"];
export const guardianFields = ["guardianApplicable"];
export const lcFields = ["serviceTypes", "isDraft", "step"];
export const sbFields = ["serviceTypes", "step", "currentForm"];
export const lsFields = ["serviceTypes", "isDraft", "step"];
export const subsidyFields = ["serviceTypes", "isDraft", "step", "isSubsidy"];

export const FatherValidation = (fatherNotApplicable) => !fatherNotApplicable;
export const MotherValidation = (motherNotApplicable) => !motherNotApplicable;
export const GuardianValidation = (guardianApplicable) => guardianApplicable;

export const LCFieldValidation = (serviceTypes, isDraft, step) => {
  if (serviceTypes.includes(LC)) {
    return (
      (!serviceTypes.includes(SB) && !isDraft) || serviceTypes.includes(SB)
    );
  }
  return false;
};
export const SBFieldValidation = (serviceTypes, step) => {
  return serviceTypes.includes(SB) && step === stepValidate;
};
export const SBFieldValidationNew = (currentStep) => {
  return (serviceTypes, step, currentForm) => {
    return (
      serviceTypes.includes(SB) && step === currentStep && currentForm === SB
    );
  };
};
export const LSFieldValidation = (serviceTypes, isDraft, step) => {
  return (
    (serviceTypes.includes(LC) && !serviceTypes.includes(SB) && !isDraft) ||
    (serviceTypes.includes(SB) && step === stepValidate)
  );
};
export const LSFieldValidationNew = (currentStep) => {
  return (serviceTypes, isDraft, step) => {
    return (
      (serviceTypes.includes(LC) && !serviceTypes.includes(SB) && !isDraft) ||
      (serviceTypes.includes(SB) && step === currentStep)
    );
  };
};
export const SubsidyValidation = (serviceTypes, isDraft, step, isSubsidy) => {
  if (serviceTypes.includes(SB)) {
    return step === stepValidate;
  } else {
    return isSubsidy && !isDraft;
  }
};
export const SubsidyValidationNew = (currentStep) => {
  return (serviceTypes, isDraft, step, isSubsidy) => {
    if (serviceTypes.includes(SB)) {
      return step === currentStep;
    } else {
      return isSubsidy && !isDraft;
    }
  };
};
