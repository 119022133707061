import React, { lazy, Suspense, useState, useEffect } from "react";
import { Header } from "../../../Components/Header/index";
import { Footer } from "../../../Components/Footer/index";
import LazyLoading from "../../../Components/LazyLoading/index";
import BannerEnquiryComplete from "../../../Components/BannerEnquiryComplete";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ModalServiceType from "../../../Components/Modal/ModalServiceType";
import { validationSchema } from "./validations/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues } from "./defaultValues";
import { apiPostalCode } from "../../../datasources/postalCodeRepo";
import {
  getGeneralPayloads,
  getLearningCurvePayloads,
  getStudyBuddyPaylods,
  managePayload,
} from "./payloads";
import {
  getPicklistValues,
  submitEnquiryCompleteForm,
} from "../../../datasources/enquiryRepo";
import ModalError from "../../../Components/Modal/ModalError";
import { retrieveData } from "./helper/retrieve-data";
import { fetchLatestData } from "./helper/fetch-latest-data";
import ToastSuccess from "../../../Components/Toast/ToastSuccess";
import ModalSave from "../../../Components/Modal/ModalSave";
import ModalSubmited from "../../../Components/Modal/ModalSubmited";
import ModalValidation from "../../../Components/Modal/ModalValidation";
import { useSyncField } from "./helper/SyncField";

const FormLearningCurveCurrent = lazy(() =>
  import(
    "../../../Components/Forms/FormEnquiryComplete/FormLearningCurveCurrent"
  )
);

const FormStudyBuddyCurrent = lazy(() =>
  import("../../../Components/Forms/FormEnquiryComplete/FormStudyBuddyCurrent")
);

const FormImpactful = () => {
  const history = useHistory();
  const [modalSave, setModalSave] = useState({
    open: false,
  });
  const [modalSubmited, setModalSubmited] = useState({
    open: false,
  });
  const [modalValidation, setModalValidation] = useState({
    show: false,
    errors: {},
  });
  const [toast, setToast] = useState({
    open: false,
    text: "Your data has been save!",
  });
  const [pillarBanner, setPillarBanner] = useState("Impactful");
  const [currentForm, setCurrentForm] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [picklistValues, setPicklistValues] = useState([]);

  const [modalServiceType, setModalServiceType] = useState({
    show: false,
    body: "",
    onYes: () => {},
    already: false,
  });
  const [modalError, setModalError] = useState({
    show: false,
    error: null,
    message: null,
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  useSyncField(methods, currentForm);

  useEffect(() => {
    methods.setValue("subjects", []);
  }, [methods.watch("educationLevel")]);

  const updatePicklistValues = async () => {
    try {
      let response = await getPicklistValues();
      setPicklistValues(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    retrieveData(history, methods, setPillarBanner, setCurrentForm);
    updatePicklistValues();
    (async () => {
      try {
        await fetchLatestData(
          history,
          methods,
          setPillarBanner,
          setCurrentForm
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingData(false);
      }
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 710);
    methods.setValue("currentForm", currentForm || "");
  }, [currentForm]);

  useEffect(() => {
    if (!loadingSubmit) {
      window.scrollTo(0, 710);
    }
  }, [methods.watch("step"), loadingSubmit]);

  useEffect(() => {
    setModalServiceType({ ...modalServiceType, show: false });
    if (!methods.watch("serviceTypes").includes("Study Buddy"))
      setCurrentForm("Learning Curve");
    if (!methods.watch("serviceTypes").includes("Learning Curve"))
      setCurrentForm("Study Buddy");
  }, [methods.watch("serviceTypes")]);

  useEffect(() => {
    if (methods.watch("postalCode")?.length === 6) {
      apiPostalCode({ postalCode: methods.watch("postalCode") })
        .then((res) => {
          methods.clearErrors("address");
          methods.setValue(
            "address",
            res?.data?.postalcode?.where?.email[
              res?.data?.postalcode?.where?.email?.length - 1
            ]
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [methods.watch("postalCode")]);

  useEffect(() => {
    const levels = [
      "Secondary 3 Express",
      "Secondary 4/5 OLevel",
      "Primary 1",
      "Primary 2",
      "Primary 3",
    ];
    if (levels.includes(methods.watch("educationLevel"))) {
      methods.setValue("stream", "");
      methods.clearErrors("stream");
    } else {
      methods.trigger("stream");
    }
  }, [methods.watch("educationLevel"), methods.watch("stream")]);

  const onLeaveServiceTypes = () => {
    if (!modalServiceType.already) {
      if (!methods.watch("serviceTypes").includes("Learning Curve")) {
        setModalServiceType({
          show: true,
          body: "Learning Curve is a tuition programme to help your kid get better marks",
          onYes: () => {
            methods.setValue("serviceTypes", [
              ...methods.watch("serviceTypes"),
              "Learning Curve",
            ]);
          },
          already: true,
        });
      } else if (!methods.watch("serviceTypes").includes("Study Buddy")) {
        setModalServiceType({
          show: true,
          body: "Study buddy programme can help your child learn better in a group",
          onYes: () => {
            methods.setValue("serviceTypes", [
              ...methods.watch("serviceTypes"),
              "Study Buddy",
            ]);
          },
          already: true,
        });
      }
    }
  };

  const handleStep = (action) => {
    switch (action) {
      case "next":
        methods.setValue("step", methods.watch("step") + 1);
        break;
      case "back":
        if (methods.watch("serviceTypes").includes("Learning Curve")) {
          if (methods.watch("step") === 0) {
            setCurrentForm("Learning Curve");
          }
        }
        if (methods.watch("step") !== 0) {
          methods.setValue("step", methods.watch("step") - 1);
        }
        break;
    }
  };

  const openModalValidation = () => {
    if (Object.entries(methods.formState.errors).length > 0) {
      setModalValidation({
        ...modalValidation,
        show: true,
        errors: methods.formState.errors,
      });
    }
  };

  const onSubmit = async () => {
    let generalPayloads = getGeneralPayloads(methods.watch());
    let learningCurvePayloads = getLearningCurvePayloads(methods.watch());
    let studyBuddyPayloads = getStudyBuddyPaylods(methods.watch());

    let serviceTypes = methods.watch("serviceTypes");
    const LC = "Learning Curve";
    const SB = "Study Buddy";
    try {
      let storePayloads = {};
      setLoadingSubmit(true);
      if (/^(received|contacted)/i.test(methods.watch("enquiryStatus"))) {
        if (serviceTypes.includes(LC) && !serviceTypes.includes(SB)) {
          storePayloads = { ...generalPayloads, ...learningCurvePayloads };
          await submitEnquiryCompleteForm(managePayload(storePayloads));
          if (storePayloads.isDraft) {
            setToast({ ...toast, open: true });
          } else {
            setModalSubmited({ ...modalSubmited, open: true });
          }
        } else if (!serviceTypes.includes(LC) && serviceTypes.includes(SB)) {
          storePayloads = { ...generalPayloads, ...studyBuddyPayloads };
          await submitEnquiryCompleteForm(managePayload(storePayloads));
          if (storePayloads.step === 3) {
            setModalSubmited({ ...modalSubmited, open: true });
          }
        } else if (serviceTypes.includes(LC) && serviceTypes.includes(SB)) {
          storePayloads = {
            ...generalPayloads,
            ...learningCurvePayloads,
            ...studyBuddyPayloads,
          };
          await submitEnquiryCompleteForm(managePayload(storePayloads));
          if (storePayloads.step === 3) {
            setModalSubmited({ ...modalSubmited, open: true });
          }
        }
      }
    } catch (error) {
      setModalError({
        show: true,
        error: error,
        message: error.response?.data?.message
          ? error.response?.data?.message
          : error.message,
      });
    } finally {
      if (methods.watch("step") < 2 && currentForm === "Study Buddy") {
        handleStep("next");
      }
      setLoadingSubmit(false);
    }

    if (
      methods.watch("serviceTypes").includes("Study Buddy") &&
      currentForm !== "Study Buddy"
    )
      setCurrentForm("Study Buddy");
  };

  return (
    <>
      <Suspense fallback={<LazyLoading />}>
        <Header />
        <BannerEnquiryComplete
          pillar={pillarBanner}
          services={methods.watch("serviceTypes")}
        />
        <Box
          sx={{
            display: loadingData ? "block" : "none",
            marginBottom: "120px",
          }}
        >
          <Stack
            direction={"column"}
            justifyContent="center"
            alignItems={"center"}
            gap={3}
          >
            <CircularProgress />
            <Typography color={"primary"} fontWeight={600}>
              Please wait...
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ display: !loadingData ? "block" : "none" }}>
          <FormProvider {...methods}>
            <Box
              sx={{
                display:
                  currentForm === "Learning Curve" &&
                  methods.watch("serviceTypes").includes("Learning Curve")
                    ? "block"
                    : "none",
              }}
            >
              <FormLearningCurveCurrent
                loadingSubmit={loadingSubmit}
                onSubmit={onSubmit}
                race={methods.watch("race")}
                onLeaveServiceTypes={onLeaveServiceTypes}
                picklistValues={picklistValues}
              />
            </Box>

            <Box
              sx={{
                display:
                  currentForm === "Study Buddy" &&
                  methods.watch("serviceTypes").includes("Study Buddy")
                    ? "block"
                    : "none",
              }}
            >
              <FormStudyBuddyCurrent
                loadingSubmit={loadingSubmit}
                onSubmit={onSubmit}
                race={methods.watch("race")}
                onLeaveServiceTypes={onLeaveServiceTypes}
                activeStep={methods.watch("step")}
                handleStep={handleStep}
                picklistValues={picklistValues}
                openModalValidation={openModalValidation}
              />
            </Box>
          </FormProvider>
        </Box>

        <ModalServiceType
          show={modalServiceType.show}
          close={() =>
            setModalServiceType({ ...modalServiceType, show: false })
          }
          body={modalServiceType.body}
          onYes={modalServiceType.onYes}
        />
        <ModalError
          show={modalError.show}
          error={modalError.error}
          close={() =>
            setModalError({ show: false, error: null, message: null })
          }
          message={modalError.message}
        />
        <ModalValidation
          show={modalValidation.show}
          onClose={() =>
            setModalValidation({ ...modalValidation, show: false })
          }
          errors={modalValidation.errors}
        />
        <ModalSave
          open={modalSave.open}
          onClose={() => setModalSave({ ...modalSave, open: false })}
        />
        <ModalSubmited
          open={modalSubmited.open}
          onClose={() => setModalSubmited({ ...modalSubmited, open: false })}
        />

        <ToastSuccess
          open={toast.open}
          close={() => setToast({ ...toast, open: false })}
          text={toast.text}
        />

        <Footer />
      </Suspense>
    </>
  );
};

export default FormImpactful;
